@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
  }
}